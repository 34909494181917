/* @media only screen and (min-device-width: 481px) { */
  .container {
    background-color: #fafafa;
    color: #2d3142;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .centeredContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: initial;
    height: 350px;
    width: 550px;
    border-style: solid;
    border-width: 2px;
    border-color: #dce0e7;
    border-radius: 20px;
    padding: 10px 80px;
  }

  .resultContainer {
    background-color: #fff;
    color: #2d3142;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .dashboardContainer {
    background-color: #fafafa;
    color: #2d3142;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-family: "Poppins";
    max-width: 60%;
  }

  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: "Poppins";
  }

  .contentText {
    line-height: 2rem;
    font-size: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .buttonText {
    font-family: "Poppins";
    font-size: 18px;
    margin: 18px 0px;
  }

  .largeButtonText {
    font-family: "Poppins";
    font-size: 24px;
  }

  .content {
    margin-bottom: 60px;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    /* Changed from row to column */
    align-items: center;
    /* To center align children vertically */
    width: 100%;
  }

  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    font-family: "Poppins";
  }

  .backNavigator {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Poppins";
    font-size: 10px;
    cursor: pointer;
  }

  .urlInput {
    padding: 1rem;
    border-radius: 20px;
    border-color: #d9d9d9;
    border-width: 1px;
    margin-bottom: 1rem;
    font-family: "Poppins";
    background-color: #ffffff;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    outline: 0;
    width: 900px;
    min-height: 450px;
    resize: vertical;
  }

  .urlInput::placeholder {
    font-family: "Poppins";
    color: #9b9b9b;
    font-size: 18px;
    font-weight: 700;
  }

  .urlInput:hover {
    box-shadow: 0 0 1px 1px #734df6;
    /* This adds the orange glow */
  }

  .urlInput:focus {
    box-shadow: 0 0 5px 2px #734df6;
    /* This adds the orange glow */
  }

  .tabContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .tabContainer button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ebebeb;
    margin: 0 0.5rem;
    transition: background-color 0.3s;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins";
  }

  .tabContainer button.activeTab {
    background-color: #734df6;
    color: #fff;
  }

  .editPageSubmitButtonGroup {
    width: 900px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: fixed;
    bottom: 20px;
    align-items: center;
    height: 80px;
  }

  .editPageSubmitButton {
    padding: 15px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ebebeb;
    margin-left: 20px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    height: 100%;
  }
  .editPageSubmitButton:hover {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  }

  .editPageSubmitButtonPurple {
    padding: 15px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ebebeb;
    margin-left: 20px;
    filter: drop-shadow(0px 4px 10px rgba(115, 77, 246, 0.6));
    height: 100%;
    text-align: initial;
    background-color: #734df6;
  }
  .editPageSubmitButtonPurple:hover {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  }

  .customizedInputContainer {
    border-style: solid;
    border-width: 1px;
    border-color: #d9d9d9;
    border-radius: 20px;
    font-size: 16px;
    padding: 15px 40px;
    width: 900px;
    margin: 10px 0px;
    background-color: #fff;
    text-align: start;
    box-shadow: 0 0 1px 1px #734df6;
    /* This adds the orange glow */
  }

  .resultPageContentContainer {
    text-align: initial;
    width: 360px;
    background-color: #fff;
    border-style: solid;
    border-color: #d9d9d9;
    border-width: 1px;
    border-radius: 20px;
    padding: 20px 30px;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .resultPageButton {
    padding: 15px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #734df6;
    width: 200px;
    margin-bottom: 20px;
  }

  .customizedInputBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 20px;
    align-items: center;
  }

  .customizedInput {
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #ebebeb;
    font-size: 16px;
    color: #000;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
    align-self: center;
    text-align: start;
  }

  .customizedInput::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #828282;
  }

  .customizedInput:hover {
    box-shadow: 0 0 1px 1px #cabcff;
    /* This adds the orange glow */
  }

  .dashboardCustomizedInput {
    padding: 15px 40px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #d9d9d9;
    color: #000;
    outline: 0;
    width: 900px;
    box-sizing: border-box;
    align-self: center;
    text-align: start;
    cursor: pointer;
    background-color: #fff;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .selectedDashboardCustomizedInput {
    padding: 15px 40px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #d9d9d9;
    color: #000;
    outline: 0;
    width: 900px;
    box-sizing: border-box;
    align-self: center;
    text-align: start;
    cursor: pointer;
    background-color: #fff;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 1px 1px #734df6;
    /* This adds the orange glow */
  }

  .dashboardCustomizedInput:hover {
    box-shadow: 0 0 1px 1px #734df6;
    /* This adds the orange glow */
  }

  .disabledNavigateButton {
    /* Add styles to make the button appear as desired */
    padding: 20px 40px;
    border-radius: 15px;
    border: none;
    background-color: #ebebeb;
    color: #757575;
    cursor: not-allowed;
    margin-top: 10px;
  }

  .navigateButton {
    padding: 20px 40px;
    border-radius: 15px;
    border: none;
    background-color: #734df6;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
  }

  .largeButton {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 10px;
    border: none;
    background-color: #734df6;
    color: #fff;
    cursor: pointer;
  }

  .noDisplay {
    display: none;
  }

  .loginButton {
    padding: 15px 5px;
    border-radius: 10px;
    border: none;
    background-color: #734df6;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .result {
    margin-top: 2rem;
  }

  a {
    text-decoration: none;
  }

  .fileList {
    list-style: none;
    padding-left: 0;
  }

  .fileText {
    font-size: 16px;
    margin: 5px;
    max-width: 80%;
    text-align: initial;
  }

  .fileTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .subsectionContainerFlexStart {
    margin-top: 1rem;
    text-align: initial;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: flex-start;
  }

  .subsectionContainer {
    margin-top: 1rem;
    justify-content: center;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #734df6;
    /* You can change the color here */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  .errorMessage {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 5px;
  }

  .previewBoxesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill,
        minmax(420px, 1fr));
    /* Adjust the width as needed */
    gap: 10px;
    /* Add some gap for spacing between items */
    margin-top: 20px;
  }

  .previewContainer {
    border-width: 2px;
    border-color: #ebebeb;
    height: 320px;
    border-style: solid;
    border-radius: 20px;
    margin: 10px 20px 10px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
    padding: 20px;
    position: relative;
    background-color: #fff;
  }

  .previewContainer:hover {
    background-color: #ebebeb;
  }

  .generatingText {
    margin-bottom: 0px;
  }

  .profilePic {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }

  .profilePicContainer {
    display: flex;
    justify-content: flex-end;
    align-self: center;
  }

  .profileDetailBox {
    background-color: #fff;
    font-family: "Poppins";
    border-radius: 10px;
    position: absolute;
    z-index: 10;
    margin-top: 55px;
    border-style: solid;
    border-color: #ebebeb;
    border-width: 0.5px;
    text-align: start;
    max-width: 250px;
  }

  .userName {
    margin: 20px;
    color: #2d3142;
    font-size: 16px;
    word-wrap: break-word;
    line-height: normal;
  }

  .profileSelection {
    color: #2d3142;
    font-family: "Poppins";
    cursor: pointer;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .profileSelection:hover {
    background-color: #ebebeb;
  }

  .profileSelectionText {
    margin: 15px 10px;
    color: #2d3142;
    font-size: 16px;
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 4.5rem;
    font-family: "Poppins";
    align-items: center;
    background-color: #fff;
  }

  .shownotesTextNew {
    font-size: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 0px;
  }

  .customizeSettingButton {
    border-radius: 10px;
    border: none;
    color: #757575;
    width: 50px;
    display: flex;
    justify-content: center;
    position: absolute;
    margin-left: 250px;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /* Space between buttons */
  }

  .fileUploadContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .fileSelectButton {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    background-color: #ebebeb;
    color: #000;
  }

  .addVoiceButton {
    padding: 15px 20px;
    border-radius: 20px;
    border: none;
    background-color: #ebebeb;
    color: #000;
    width: 100%;
  }

  .addVoiceContainer {
    width: 180px;
    position: absolute;
    left: calc(100% + 30px);
    /* Adjust this margin as needed */
    white-space: nowrap;
    /* transform: translateX(-100%); Pull the element back by 100% of its own width */
  }

  .fileUploadButton {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    background-color: #734df6;
    color: #fff;
    cursor: pointer;
  }

  .disabledFileUploadButton {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    background-color: #ebebeb;
    color: #000;
    cursor: not-allowed;
  }

  .selectionDropDownContainer {
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #ebebeb;
    z-index: 10;
    position: absolute;
    width: 900px;
    background-color: white;
    max-height: 400px;
    overflow: auto;
  }

  .selectionDropDownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 40px;
    border-bottom: 0.5px solid #ebebeb;
    border-radius: 20px;
  }

  .selectionDropDownItem:hover {
    box-shadow: 0 0 1px 1px #cabcff;
    /* This adds the orange glow */
  }

  .tagText {
    background-color: #e9e5f9;
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 15px;
  }

  .previewTagText {
    background-color: #3f1691;
    padding: 5px 15px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .divider {
    border-style: solid;
    border-width: 0.5px;
    border-color: #ebebeb;
  }

  .headerDivider {
    border-style: solid;
    border-width: 1px;
    border-color: #dce0e7;
    width: 100%;
  }

  .subscriptionContainer {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 3%;
  }

  .subscriptionPlanText {
    font-family: "Poppins";
    font-size: 14px;
    margin-top: 5%;
  }

  .alertBoxContainer {
    width: 1100px;
    border-style: solid;
    border-color: #ebebeb;
    border-radius: 20px;
    position: fixed;
    z-index: 10;
    padding: 20px 40px;
    background-color: #fff;
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Center the modal in the middle */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .editParagraphControlButtonsGroup {
    position: absolute;
    left: calc(100% + 30px);
    white-space: nowrap;
    bottom: 225px;
  }

  /* Background Overlay Styles */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent grey background */
    z-index: 9;
    /* Lower z-index than the modal, but higher than other content */
  }

  .helperContainer {
    width: 900px;
    border-style: solid;
    border-color: #ebebeb;
    border-radius: 20px;
    background-color: #fff;
    margin: 0px 0px 10px 0px;
    padding: 20px;
    position: relative;
  }

  .betaTag {
    background-color: #d0f64d;
    margin-left: 20px;
    padding: 5px 15px;
    border-radius: 15px;
  }

  .dashboardHeaderText {
    font-family: "Poppins";
    margin: 0px;
    font-weight: 700;
    font-size: 30px;
    color: #2b1c50;
  }

  .subsectionHeaderText {
    font-family: "Poppins";
    margin: 0px;
    font-weight: 700;
    font-size: 38px;
    color: #2b1c50;
    text-align: start;
  }

  .navigationHeaderText {
    font-family: "Poppins";
    margin: 0px;
    font-weight: 700;
    font-size: 28px;
    color: #2b1c50;
    text-align: start;
  }

  .plainText {
    font-family: "Poppins";
    margin: 0px;
    font-size: 18px;
    font-weight: 700;
  }

  .plainText28px {
    font-family: "Poppins";
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
  }

  .plainText16px {
    font-family: "Poppins";
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }

  .plainText14px {
    font-family: "Poppins";
    margin: 0px;
    font-size: 14px;
    font-weight: 700;
  }

  .helperText {
    font-family: "Poppins";
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
  }

  .podcastSettingsText {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    color: #4d4d4d;
    margin: 10px 0px;
  }

  .greyBoldText {
    font-family: "Poppins";
    margin: 0px;
    color: #777777;
    font-size: 16px;
    font-weight: 700;
  }

  .saveVoiceButton {
    background-color: #734df6;
    border-radius: 100px;
    border: none;
    padding: 10px 30px;
    margin: 10px 0px 20px 0px;
    width: 220px;
  }

  .fileUploadForm {
    height: 16rem;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    width: 100%;
  }

  .fileUploadForm.drag-active {
    background-color: #ffffff;
  }

  .fileUploadLabel {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #fff;
  }

  .dragFileElement {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .fileUploadItem {
    background-color: #f8fafc;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .waitForResultContainer {
    background-color: #F0F0F0;
    border-radius: 30px;
    width: 900px;
    text-align: initial;
    padding: 50px 50px;
    margin-bottom: 60px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* To be compatible with bootstrap */

  .customizedInputField {
    font-size: 16px;
    margin: 16px 0px;
  }

  /* toggle */
  .react-toggle {
    /* touch-action: pan-x; */

    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #734df6;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #734df6;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #734df6;
  }

  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #734df6;
    -moz-box-shadow: 0px 0px 3px 2px #734df6;
    box-shadow: 0px 0px 2px 3px #734df6;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #734df6;
    -moz-box-shadow: 0px 0px 5px 5px #734df6;
    box-shadow: 0px 0px 5px 5px #734df6;
  }

  .previewBoxesContainer1 {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin-top: 20px;
    color: red;
  }

  .notification {
    position: fixed;
    top: 10px;
    width: 10%;
    padding: 10px;
    text-align: center;
    z-index: 100;
    /* Ensures it appears above other content */
  }

  .shareTabButton {
    background-color: #E9E8EC;
    border-radius: 20px;
    border: none;
    padding: 10px 30px;
  }

  .shareTabButtonDisabled {
    background-color: #fff;
    border: none;
    padding: 10px 30px;
  }

  .shareMessageInput {
    padding: 1rem;
    border-radius: 20px;
    border-color: #734DF6;
    border-width: 2px;
    margin-bottom: 1rem;
    font-family: "Poppins";
    background-color: #ffffff;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    outline: 0;
    width: 70%;
    min-height: 200px;
    max-height: 500px;
    resize: none;
    box-shadow: 0 0 1px 2px #B0AEFB;
  }
  
  .shareContainerFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(247, 246, 240);
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 0px 50px;
    height: 80px;
  }

  #languagePill:hover {
    background-color: #d9d1fb;
  }
/* } */